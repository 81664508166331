import React from "react";
import { Link } from "gatsby";
import { Row, Col, Layout, Card, Alert } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../components/common/header";
import CommonFooter from "../components/common/footer";

import {
  FreePlanList,
  ProPlanList,
  TeamPlanList,
} from "../components/plans/plans";

const { Content } = Layout;

class PlansPage extends React.Component {
  state = {
    key: "tab1",
  };

  onTabChange = (key, type) => {
    console.log(key, type);
    this.setState({ [type]: key });
  };
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>DayViewer Planner - Plans &amp; Pricing</title>
          <meta
            name="description"
            content="DAYVIEWER - Powerful and flexible all in one calendar planner and organizer for professionals and teams"
          />
        </Helmet>
        <Layout>
          <CommonHeader />
        </Layout>
        <Layout style={{ minHeight: "100vh" }}>
          <Content className="homepage-background">
            {/* <Alert
              message="New Users Promotion"
              description={
                <div>
                  New users automatically have DayViewer Pro enabled for 7 days
                  giving you time to evalute the potential of DayViewer in full.
                  <a href="/"> Sign Up Today</a>
                </div>
              }
              type="info"
              showIcon
              closable
            /> */}
            <Row>
              <Col>
                <div>
                  <div>
                    <div className="homepage-headlines">
                      <div style={{ textAlign: "left !important" }}>
                        <h1>Plans &amp; Pricing</h1>
                        <p>
                          Get started with the Starter Plan -&gt; Go Pro for the
                          full, unrestricted version.
                        </p>
                        <p>
                          All prices are in USD and exclude local tax (where
                          applicable, the rate varies dependant on residence).
                        </p>
                      </div>
                    </div>
                    <div>
                      <Card
                        style={{
                          width: "100%",
                          background: "#cdd5e673",
                          border: "none",
                        }}
                      >
                        <Row>
                          <Col xs={24} lg={8}>
                            <Card className="plan-cards">
                              <FreePlanList />
                            </Card>
                          </Col>
                          <Col xs={24} lg={8}>
                            <Card className="plan-cards">
                              <ProPlanList />
                            </Card>
                          </Col>
                          <Col xs={24} lg={8}>
                            <Card className="plan-cards">
                              <TeamPlanList />
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                    <div />
                  </div>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
        <Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default PlansPage;
